/* COLOR VARIABLES */
//background
$gunmetal: #2e3436;
//text
$greynurse: #d3d7cf;
//dark background
$nero: #1c1c1c;
//headers
$wheat: #ffd7af;
//highlights
$daisy: #fce94f;
//links
$java: #06989a;
//critical
$pink: #d7afaf;
$mypink: #d78787;
//others
$pavlova: #afaf87;
$earlsgreen: #c4a000;
$dimgray: #767676;
$greygreen: #7f9f7f;
//highlights-hack
//for Chrome
$selection: rgba(252, 233, 79, 0.99);

/* MEDIA QUERIES */
$phone: "max-width: 684px";
$tablet: "max-width: 900px";

/* variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}
