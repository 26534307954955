.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media print {
    display: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    flex: 1;
    div {
        background: $java;
        &:hover {
            background: $daisy;
        }
    }

    &:after {
      content: '|---[π]---->';
      display: block;
      color: $wheat;
      font-size: 1.4em;
      width: 100%;
      right: 10px;
    }

    a {
      flex: 0 0 auto;
      max-width: 100%;
      text-decoration: none;
    }
  }
}

.header__logo {
    color: red;
}

.about {
  display: flex;
  align-items: center;
  justify-content: center;
}
